import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _74b5cd4c = () => interopDefault(import('..\\pages\\error.vue' /* webpackChunkName: "pages_error" */))
const _a482b75a = () => interopDefault(import('..\\pages\\have-a-card.vue' /* webpackChunkName: "pages_have-a-card" */))
const _1cd68662 = () => interopDefault(import('..\\pages\\have-a-card\\index.vue' /* webpackChunkName: "pages_have-a-card_index" */))
const _66131057 = () => interopDefault(import('..\\pages\\have-a-card\\enrollment.vue' /* webpackChunkName: "pages_have-a-card_enrollment" */))
const _fabe6380 = () => interopDefault(import('..\\pages\\have-a-card\\success.vue' /* webpackChunkName: "pages_have-a-card_success" */))
const _5539fddb = () => interopDefault(import('..\\pages\\rejection.vue' /* webpackChunkName: "pages_rejection" */))
const _284fc57a = () => interopDefault(import('..\\pages\\request-services.vue' /* webpackChunkName: "pages_request-services" */))
const _5cde8e62 = () => interopDefault(import('..\\pages\\unlock.vue' /* webpackChunkName: "pages_unlock" */))
const _f6467890 = () => interopDefault(import('..\\pages\\upload-documents\\index.vue' /* webpackChunkName: "pages_upload-documents_index" */))
const _230c647b = () => interopDefault(import('..\\pages\\errors\\400.vue' /* webpackChunkName: "pages_errors_400" */))
const _231a7bfc = () => interopDefault(import('..\\pages\\errors\\401.vue' /* webpackChunkName: "pages_errors_401" */))
const _2336aafe = () => interopDefault(import('..\\pages\\errors\\403.vue' /* webpackChunkName: "pages_errors_403" */))
const _2344c27f = () => interopDefault(import('..\\pages\\errors\\404.vue' /* webpackChunkName: "pages_errors_404" */))
const _57f29fbc = () => interopDefault(import('..\\pages\\errors\\500.vue' /* webpackChunkName: "pages_errors_500" */))
const _2af8ea72 = () => interopDefault(import('..\\pages\\patient\\authorization.vue' /* webpackChunkName: "pages_patient_authorization" */))
const _fe397074 = () => interopDefault(import('..\\pages\\patient\\eligibility.vue' /* webpackChunkName: "pages_patient_eligibility" */))
const _79e00867 = () => interopDefault(import('..\\pages\\patient\\injection-training.vue' /* webpackChunkName: "pages_patient_injection-training" */))
const _7960b0d3 = () => interopDefault(import('..\\pages\\patient\\insurance.vue' /* webpackChunkName: "pages_patient_insurance" */))
const _7eaba09c = () => interopDefault(import('..\\pages\\patient\\insurance-information.vue' /* webpackChunkName: "pages_patient_insurance-information" */))
const _65555054 = () => interopDefault(import('..\\pages\\patient\\more-service.vue' /* webpackChunkName: "pages_patient_more-service" */))
const _31b48fd0 = () => interopDefault(import('..\\pages\\patient\\nurse-ambassador.vue' /* webpackChunkName: "pages_patient_nurse-ambassador" */))
const _faa9fbc6 = () => interopDefault(import('..\\pages\\patient\\patient-information.vue' /* webpackChunkName: "pages_patient_patient-information" */))
const _03180bdb = () => interopDefault(import('..\\pages\\patient\\prescriber-information.vue' /* webpackChunkName: "pages_patient_prescriber-information" */))
const _4095d285 = () => interopDefault(import('..\\pages\\patient\\selection.vue' /* webpackChunkName: "pages_patient_selection" */))
const _fc3d0116 = () => interopDefault(import('..\\pages\\patient\\services.vue' /* webpackChunkName: "pages_patient_services" */))
const _6879ce14 = () => interopDefault(import('..\\pages\\patient\\sharps-container.vue' /* webpackChunkName: "pages_patient_sharps-container" */))
const _12a431bc = () => interopDefault(import('..\\pages\\patient\\success.vue' /* webpackChunkName: "pages_patient_success" */))
const _c9c0eee8 = () => interopDefault(import('..\\pages\\pharmacy\\eligibility.vue' /* webpackChunkName: "pages_pharmacy_eligibility" */))
const _d50e9e10 = () => interopDefault(import('..\\pages\\pharmacy\\insurance-information.vue' /* webpackChunkName: "pages_pharmacy_insurance-information" */))
const _6c91e734 = () => interopDefault(import('..\\pages\\pharmacy\\ncpdp.vue' /* webpackChunkName: "pages_pharmacy_ncpdp" */))
const _69951ce3 = () => interopDefault(import('..\\pages\\pharmacy\\patient-information.vue' /* webpackChunkName: "pages_pharmacy_patient-information" */))
const _e40874fc = () => interopDefault(import('..\\pages\\pharmacy\\success.vue' /* webpackChunkName: "pages_pharmacy_success" */))
const _576fe1de = () => interopDefault(import('..\\pages\\provider\\diagnosis-information.vue' /* webpackChunkName: "pages_provider_diagnosis-information" */))
const _5bd5ab0e = () => interopDefault(import('..\\pages\\provider\\eligibility.vue' /* webpackChunkName: "pages_provider_eligibility" */))
const _5e1b8457 = () => interopDefault(import('..\\pages\\provider\\health-insurance-information.vue' /* webpackChunkName: "pages_provider_health-insurance-information" */))
const _668f18fa = () => interopDefault(import('..\\pages\\provider\\insurance-information.vue' /* webpackChunkName: "pages_provider_insurance-information" */))
const _75c99d65 = () => interopDefault(import('..\\pages\\provider\\patient-information.vue' /* webpackChunkName: "pages_provider_patient-information" */))
const _5b23cbb5 = () => interopDefault(import('..\\pages\\provider\\pharmacy-prescription.vue' /* webpackChunkName: "pages_provider_pharmacy-prescription" */))
const _19d04b93 = () => interopDefault(import('..\\pages\\provider\\prescriber-information.vue' /* webpackChunkName: "pages_provider_prescriber-information" */))
const _61d867cd = () => interopDefault(import('..\\pages\\provider\\selection.vue' /* webpackChunkName: "pages_provider_selection" */))
const _56c90df8 = () => interopDefault(import('..\\pages\\provider\\success.vue' /* webpackChunkName: "pages_provider_success" */))
const _4dcb51b2 = () => interopDefault(import('..\\pages\\provider\\summary.vue' /* webpackChunkName: "pages_provider_summary" */))
const _f5313530 = () => interopDefault(import('..\\pages\\provider\\upload-document.vue' /* webpackChunkName: "pages_provider_upload-document" */))
const _2b30e8bb = () => interopDefault(import('..\\pages\\provider\\upload-documents.vue' /* webpackChunkName: "pages_provider_upload-documents" */))
const _5acf404a = () => interopDefault(import('..\\pages\\transition\\document-submission.vue' /* webpackChunkName: "pages_transition_document-submission" */))
const _54df31aa = () => interopDefault(import('..\\pages\\transition\\eligibility.vue' /* webpackChunkName: "pages_transition_eligibility" */))
const _fcbe6ffe = () => interopDefault(import('..\\pages\\transition\\patient-information.vue' /* webpackChunkName: "pages_transition_patient-information" */))
const _726ea4c0 = () => interopDefault(import('..\\pages\\transition\\success.vue' /* webpackChunkName: "pages_transition_success" */))
const _fc4209b8 = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages_index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('#{BasePath}#/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/error",
    component: _74b5cd4c,
    name: "error"
  }, {
    path: "/have-a-card",
    component: _a482b75a,
    children: [{
      path: "",
      component: _1cd68662,
      name: "have-a-card"
    }, {
      path: "enrollment",
      component: _66131057,
      name: "have-a-card-enrollment"
    }, {
      path: "success",
      component: _fabe6380,
      name: "have-a-card-success"
    }]
  }, {
    path: "/rejection",
    component: _5539fddb,
    name: "rejection"
  }, {
    path: "/request-services",
    component: _284fc57a,
    name: "request-services"
  }, {
    path: "/unlock",
    component: _5cde8e62,
    name: "unlock"
  }, {
    path: "/upload-documents",
    component: _f6467890,
    name: "upload-documents"
  }, {
    path: "/errors/400",
    component: _230c647b,
    name: "errors-400"
  }, {
    path: "/errors/401",
    component: _231a7bfc,
    name: "errors-401"
  }, {
    path: "/errors/403",
    component: _2336aafe,
    name: "errors-403"
  }, {
    path: "/errors/404",
    component: _2344c27f,
    name: "errors-404"
  }, {
    path: "/errors/500",
    component: _57f29fbc,
    name: "errors-500"
  }, {
    path: "/patient/authorization",
    component: _2af8ea72,
    name: "patient-authorization"
  }, {
    path: "/patient/eligibility",
    component: _fe397074,
    name: "patient-eligibility"
  }, {
    path: "/patient/injection-training",
    component: _79e00867,
    name: "patient-injection-training"
  }, {
    path: "/patient/insurance",
    component: _7960b0d3,
    name: "patient-insurance"
  }, {
    path: "/patient/insurance-information",
    component: _7eaba09c,
    name: "patient-insurance-information"
  }, {
    path: "/patient/more-service",
    component: _65555054,
    name: "patient-more-service"
  }, {
    path: "/patient/nurse-ambassador",
    component: _31b48fd0,
    name: "patient-nurse-ambassador"
  }, {
    path: "/patient/patient-information",
    component: _faa9fbc6,
    name: "patient-patient-information"
  }, {
    path: "/patient/prescriber-information",
    component: _03180bdb,
    name: "patient-prescriber-information"
  }, {
    path: "/patient/selection",
    component: _4095d285,
    name: "patient-selection"
  }, {
    path: "/patient/services",
    component: _fc3d0116,
    name: "patient-services"
  }, {
    path: "/patient/sharps-container",
    component: _6879ce14,
    name: "patient-sharps-container"
  }, {
    path: "/patient/success",
    component: _12a431bc,
    name: "patient-success"
  }, {
    path: "/pharmacy/eligibility",
    component: _c9c0eee8,
    name: "pharmacy-eligibility"
  }, {
    path: "/pharmacy/insurance-information",
    component: _d50e9e10,
    name: "pharmacy-insurance-information"
  }, {
    path: "/pharmacy/ncpdp",
    component: _6c91e734,
    name: "pharmacy-ncpdp"
  }, {
    path: "/pharmacy/patient-information",
    component: _69951ce3,
    name: "pharmacy-patient-information"
  }, {
    path: "/pharmacy/success",
    component: _e40874fc,
    name: "pharmacy-success"
  }, {
    path: "/provider/diagnosis-information",
    component: _576fe1de,
    name: "provider-diagnosis-information"
  }, {
    path: "/provider/eligibility",
    component: _5bd5ab0e,
    name: "provider-eligibility"
  }, {
    path: "/provider/health-insurance-information",
    component: _5e1b8457,
    name: "provider-health-insurance-information"
  }, {
    path: "/provider/insurance-information",
    component: _668f18fa,
    name: "provider-insurance-information"
  }, {
    path: "/provider/patient-information",
    component: _75c99d65,
    name: "provider-patient-information"
  }, {
    path: "/provider/pharmacy-prescription",
    component: _5b23cbb5,
    name: "provider-pharmacy-prescription"
  }, {
    path: "/provider/prescriber-information",
    component: _19d04b93,
    name: "provider-prescriber-information"
  }, {
    path: "/provider/selection",
    component: _61d867cd,
    name: "provider-selection"
  }, {
    path: "/provider/success",
    component: _56c90df8,
    name: "provider-success"
  }, {
    path: "/provider/summary",
    component: _4dcb51b2,
    name: "provider-summary"
  }, {
    path: "/provider/upload-document",
    component: _f5313530,
    name: "provider-upload-document"
  }, {
    path: "/provider/upload-documents",
    component: _2b30e8bb,
    name: "provider-upload-documents"
  }, {
    path: "/transition/document-submission",
    component: _5acf404a,
    name: "transition-document-submission"
  }, {
    path: "/transition/eligibility",
    component: _54df31aa,
    name: "transition-eligibility"
  }, {
    path: "/transition/patient-information",
    component: _fcbe6ffe,
    name: "transition-patient-information"
  }, {
    path: "/transition/success",
    component: _726ea4c0,
    name: "transition-success"
  }, {
    path: "/",
    component: _fc4209b8,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
